import Vue from 'vue'
import store from '../store'
import { isString } from 'lodash'

export default Vue.prototype.$session = {
  exists () {
    return !!store.state.token
  },
  set (name, value) {
    store.commit('setState', isString(name) ? { [name]: value } : name)
  },
  get (key) {
    return store.state[key]
  },
  destroy () {
    store.commit('destroy')
  }
}
