import Vue from 'vue'

Vue.directive('input-filter', inputFilter((val, param) => {
  const rg = `[^${param.join('')}]`
  return val.replace(new RegExp(rg), '')
}))

Vue.directive('uppercase', { ...inputFilter((val) => val.toUpperCase()), ...setElStyle({ textTransform: 'uppercase' }, ['input']) })
Vue.directive('lowercase', { ...inputFilter((val) => val.toLowerCase()), ...setElStyle({ textTransform: 'lowercase' }, ['input']) })
Vue.directive('capitalize', { ...inputFilter((val) => val.charAt(0).toUpperCase() + val.slice(1)), ...setElStyle({ textTransform: 'capitalize' }, ['input']) })

function inputFilter (filter) {
  return {
    update (el, binding, vnode) {
      let old = vnode.data.model.value
      if (old !== undefined) {
        old = String(old)
        let val = ''

        if (old) {
          val = filter(old, binding.value)
        }

        if (val !== old) {
          Vue.nextTick(() => {
            return (vnode.componentInstance.$emit('input', val))
          })
        }
      }
    }
  }
}

function setElStyle (style, childs = []) {
  return {
    bind (el) {
      if (childs.length > 0) {
        childs.forEach((child) => {
          Object.assign(el.querySelector(child).style, style)
        })
      } else {
        Object.assign(el.style, style)
      }
    }
  }
}
